/* General */
export const siteTenantURL = window?.PUBLIC_URL || "https://uat.onecityapp.hr";
export const siteUrl = window?.REACT_SITE_URL || "https://uat.onecityapp.hr";
export const appUrl = window?.REACT_APP_URL || "http://localhost:3000/admin";
export const apiUrl = window?.REACT_APP_API_URL || `https://uat.onecityapp.hr`;
export const loginUrl =
  window?.REACT_APP_IDENTITY_URL || "https://uat.smartcity-vodice.hr/rid";
export const logoutUrlNias =
  (window && `${window.REACT_APP_IDENTITY_URL}/Account/LogOff`) ||
  "https://uat.onecityapp.hr/id/Account/LogOff";

const isDeployed = process.env.NODE_ENV !== "development";

/* CMS */
export const cmsApiUrl = `${apiUrl}/cms`;
export const tagsUrl = `${cmsApiUrl}/content/tags`;
export const visibilitiesUrl = `${cmsApiUrl}/content/visibilities`;
export const postsUrl = `${cmsApiUrl}/content/posts`;
export const navigationUrl = `${cmsApiUrl}/content/settings/navigation`;
export const postStatusesUrl = `${cmsApiUrl}/content/posts/statuses`;
export const pageStatusesUrl = `${cmsApiUrl}/content/pages/statuses`;
export const pagesUrl = `${cmsApiUrl}/content/pages`;
export const formsUrl = `${cmsApiUrl}/content/forms`;

//export const subscriptionsUrl = `${cmsApiUrl}/tenants`;

/* Forms */
export const formApiUrl = `${apiUrl}/form/api`;
export const formApiFormsUrl = `${formApiUrl}/forms`;
export const formApiCategoriesUrl = `${formApiUrl}/categories`;
export const formApiFormStatusesUrl = `${formApiUrl}/forms/statuses`;
export const formApiRequestsUrl = `${formApiUrl}/requests`;
export const formApiRequestStatusesUrl = `${formApiUrl}/requests/statuses`;
export const formApiSurveysUrl = `${formApiUrl}/surveys`;
export const formApiRequestSurveysUrl = `${formApiUrl}/requestsurveys`;

/* Users, Customers */
export const usersApiUrl =
  (isDeployed && `${loginUrl}/api`) ||
  `${"https://uat.onecityapp.hr/id"}/api` ||
  `${apiUrl}/user`;
export const alphaIdApiRoleUrl = `${usersApiUrl}/Role`;
export const alphaIdApiUserUrl = `${usersApiUrl}/User`;
export const alphaIdApiUsersUrl = `${usersApiUrl}/Users`;
export const alphaIdApiPasswordUrl = `${usersApiUrl}/Password`;

/*export const customersApiUrl =
  (isDeployed && `${apiUrl}/customer/api`) ||
  `${"https://uat.onecityapp.hr/customer"}/api` ||
  `${apiUrl}/customer`;
export const customersAdminApiUrl =
  (isDeployed && `${apiUrl}/customer/admin`) ||
  `${"https://uat.onecityapp.hr/customer"}/admin` ||
  `${apiUrl}/customer/admin`;
export const customersAdminUrl = `${customersAdminApiUrl}/Customer`;

*/

export const customersAdminApiUrl = `${apiUrl}/customer/admin`;

export const customersAdminUrl = `${apiUrl}/customer/admin/Customer`;

export const alphaDimApiUrl =
  (isDeployed && `${apiUrl}/dim`) ||
  `${"https://uat.onecityapp.hr/dim"}` ||
  `${apiUrl}/dim`;
export const alphaDimApiServicesUrl = `${alphaDimApiUrl}/api/v1/Services`;

/* Dashboard, Payments, Invoices */
export const invoicesApiUrl = `${apiUrl}/invoice/api`;
export const invoicesAdminUrl = `${apiUrl}/invoice/api/admin`;
export const paymentsUrl = `${invoicesApiUrl}/payments`;
export const invoicesSepaUrl = `${invoicesApiUrl}/sepa-public`;
export const transactionsUrl = `${invoicesAdminUrl}/transactions`;
export const transactionStatusUrl = `${invoicesAdminUrl}/transaction-status`;
export const transactionsMonthUrl = `${invoicesAdminUrl}/month-transactions`;
export const vendorsUrl = `${invoicesAdminUrl}/vendors`;
export const transactionsTotalValueUrl = `${invoicesAdminUrl}/total-payment-transactions-value`;

/* Storage */
export const storageApiUrl = `${apiUrl}/storage/api`;
export const filesUrl = `${storageApiUrl}/file`;

/* Ticketing */
export const ticketingApiUrl = `${apiUrl}/ticketing/api`;
export const chatApiUrl = `${apiUrl}/chat/api`;
export const ticketsUrl = `${ticketingApiUrl}/tickets`;

/* Notifications  */
export const notificationsApiUrl = `${apiUrl}/notification/api`;
export const notificationsTenantApiUrl = `${siteTenantURL}/notification/api`;
/* Organization structure */
export const organizationStructureUrl = `${apiUrl}/organization-structure`;

/* Language */
export const languageApiUrl = `${apiUrl}/language/api`;
export const subscriptionsApiUrl = `${apiUrl}/subscription`;
