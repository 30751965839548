import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "@/Contexts/GlobalContext";
import { AuthContext } from "@/Contexts/AuthContext";
import { Page } from "@/Components/Layout";
import {
  Carousel,
  FlexboxGrid,
  Button,
  Message,
  Permission,
} from "@/Components/UI";
import LineChart from "./LineChart";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import TopPosts from "./TopPosts";
import { DashboardPieChart } from "./DashboardPieChart";
import ExternalPosts from "./ExternalPosts";
import HelpCenter from "./HelpCenter";
import { formatCurrency } from "@/Helpers/NumberHelper";
import { Col } from "rsuite";
import {
  userHooks,
  customerHooks,
  formHooks,
  postHooks,
  ticketingHooks,
  invoiceHooks,
  notificationHooks,
} from "@/Hooks";
import "./Home.css";
import { hasAuth } from "@/Helpers/AuthHelper";
import { OidcConfig } from "@/Config/OidcConfig";
import { notificationService } from "../../Services";
import { Modal } from "../../Components/UI";
import { SubscriptionContext } from "../../Contexts/SubscriptionContext";
import { SUBSCRIPTION_FEATURE_CODE } from "../../Constants/SubscriptionConstants";

const Home = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [messageDetails, setMessageDetails] = useState(false);
  const { openSuccessModal, errorHandler } = useContext(GlobalContext);
  const { subscription } = useContext(SubscriptionContext);
  const { loginUser, handleLogin } = useContext(AuthContext);
  const appId = OidcConfig.client_id;

  const { data: userDashboard } = userHooks.useDashboard();
  const { data: customerDashboard } = customerHooks.useDashboard();
  const { data: formDashboard } = formHooks.useDashboard();
  const { data: cmsDashboard, mutate: mutatePosts } = postHooks.useDashboard();
  const { data: ticketingDashboard } = ticketingHooks.useDashboard();
  const { data: invoiceDashboard } = invoiceHooks.useDashboard();
  const { data: internalNotificationData, mutate: mutateInternalNotification } =
    notificationHooks.useInternalNotification();
  const { data: unreadCount, mutate: mutateUnreadCount } =
    notificationHooks.useUnreadNotificationCount();

  const handleOnPostsChange = () => {
    mutatePosts();
  };

  const readNotification = async (id) => {
    await notificationService.read(id);
    await mutateUnreadCount();
  };

  const handleInternalNotificationClose = async (id) => {
    await readNotification(id);
    mutateInternalNotification();
  };

  const userAuthorized = hasAuth(loginUser);

  const subFeatures = subscription.subscriptionFeatures;
  console.log("subs", subscription);

  return (
    <Page wide>
      <Page.Header />

      {(!userAuthorized && (
        <Message
          title={t("Warning")}
          description={t("You are not authorized to access this application")}
        />
      )) || (
        <>
          <FlexboxGrid justify="space-between">
            {internalNotificationData &&
              internalNotificationData.results &&
              internalNotificationData.results.length > 0 && (
                <FlexboxGrid.Item componentClass={Col} xs={24}>
                  <Carousel>
                    {internalNotificationData.results.map((d, i) => (
                      <div
                        onClick={() => setMessageDetails(d)}
                        style={{ cursor: "pointer" }}
                      >
                        <Message
                          title={d.title}
                          description={d.shortDescription}
                          onClose={() => handleInternalNotificationClose(d.id)}
                        />
                      </div>
                    ))}
                  </Carousel>
                  <Modal
                    show={messageDetails}
                    onHide={() => setMessageDetails(false)}
                  >
                    <Modal.Header>
                      <Modal.Title>{messageDetails.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: messageDetails.message || "",
                        }}
                      ></div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        onClick={() => setMessageDetails(false)}
                        appearance="subtle"
                      >
                        {t("Cancel")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </FlexboxGrid.Item>
              )}
            <FlexboxGrid.Item componentClass={Col} xs={24}>
              <section className="home__sc_statistics">
                {subFeatures.some(
                  (obj) =>
                    obj.featureCode === SUBSCRIPTION_FEATURE_CODE.TICKETING
                ) && (
                  <>
                    <Permission claims={[`${appId}.ticketing.read`]}>
                      <div className="home__sc_statistics__item">
                        <div className="home__sc_statistics__title">
                          {ticketingDashboard
                            ? ticketingDashboard.openTickets
                            : "-"}
                        </div>
                        <div className="home__sc_statistics__subtitle">
                          {t("Open tickets")}
                        </div>
                      </div>
                      <div className="home__sc_statistics__item">
                        <div className="home__sc_statistics__title">
                          {ticketingDashboard
                            ? ticketingDashboard.highPriorityTickets
                            : "-"}
                        </div>
                        <div className="home__sc_statistics__subtitle">
                          {t("High priority tickets")}
                        </div>
                      </div>
                    </Permission>

                    <Permission claims={[`${appId}.requests.read`]}>
                      <div className="home__sc_statistics__item">
                        <div className="home__sc_statistics__title">
                          {formDashboard?.submited || "0"}
                        </div>
                        <div className="home__sc_statistics__subtitle">
                          {t("Requests waiting approval")}
                        </div>
                      </div>
                    </Permission>
                  </>
                )}

                <div className="home__sc_statistics__item">
                  <div className="home__sc_statistics__title">
                    {cmsDashboard ? cmsDashboard.externalDraftCount : "-"}
                  </div>
                  <div className="home__sc_statistics__subtitle">
                    {t("External post waiting approval")}
                  </div>
                </div>
                {subFeatures.some(
                  (obj) =>
                    obj.featureCode === SUBSCRIPTION_FEATURE_CODE.INVOICES
                ) && (
                  <Permission claims={[`${appId}.reporting.payments`]}>
                    <div className="home__sc_statistics__item">
                      <div className="home__sc_statistics__title">
                        {invoiceDashboard
                          ? formatCurrency(
                              invoiceDashboard.platformPayedTotal,
                              i18n.language
                            )
                          : "-"}
                      </div>
                      <div className="home__sc_statistics__subtitle">
                        {t("Payment amount via platform")}
                      </div>
                    </div>
                  </Permission>
                )}
                <Permission claims={[`${appId}.users.read`]}>
                  <div className="home__sc_statistics__item">
                    <div className="home__sc_statistics__title">
                      {userDashboard ? userDashboard.userCount : "-"}
                    </div>
                    <div className="home__sc_statistics__subtitle">
                      {t("Active users")}
                    </div>
                  </div>
                </Permission>
                <Permission claims={[`${appId}.customers.read`]}>
                  <div className="home__sc_statistics__item">
                    <div className="home__sc_statistics__title">
                      {customerDashboard
                        ? customerDashboard.customerCount
                        : "-"}
                    </div>
                    <div className="home__sc_statistics__subtitle">
                      {t("Number of customers")}
                    </div>
                  </div>
                </Permission>
              </section>
            </FlexboxGrid.Item>
            {/* <Permission claims={[`${appId}.users.read`]}>
                        <FlexboxGrid.Item componentClass={Col} xs={24} sm={9} style={{ marginBottom: '1.8vw' }}>
                            <ParentSize>{({ width, height }) => <LineChart style={{ borderRadius: '3px' }} width={width} height="300" title="Broj novih korisnika" subtitle="Broj novih korisnika od kada je sustav pušten u rad" />}</ParentSize>
                        </FlexboxGrid.Item>
                    </Permission> */}
            {subFeatures.some(
              (obj) => obj.featureCode === SUBSCRIPTION_FEATURE_CODE.TICKETING
            ) && (
              <Permission claims={[`${appId}.ticketing.read`]}>
                <FlexboxGrid.Item componentClass={Col} xs={24} md={15}>
                  {ticketingDashboard && (
                    <ParentSize>
                      {({ width, height }) => (
                        <>
                          <DashboardPieChart
                            width={width}
                            height="300"
                            data={[
                              {
                                id: "A",
                                label: t("High priority tickets"),
                                usage: ticketingDashboard.highPriorityTickets,
                                pattern: {
                                  type: "PatternLines",
                                  orientation: ["vertical"],
                                  width: 7,
                                  strokeWidth: 2,
                                  background: "rgba(255,255,255,0.3)",
                                },
                              },
                              {
                                id: "B",
                                label: t("Open tickets"),
                                usage: ticketingDashboard.openTickets,
                                pattern: {
                                  type: "PatternLines",
                                  orientation: ["horizontal"],
                                  height: 5,
                                  width: 5,
                                  background: "rgba(255,255,255,0.3)",
                                },
                              },
                              {
                                id: "C",
                                label: t("My high priority tickets"),
                                usage: ticketingDashboard.myHighPriorityTickets,
                                pattern: {
                                  type: "PatternCircles",
                                  height: 6,
                                  width: 6,
                                  stroke: "white",
                                },
                              },
                              {
                                id: "D",
                                label: t("My open tickets"),
                                usage: ticketingDashboard.myOpenTickets,
                                pattern: {
                                  type: "PatternWaves",
                                  background: "rgba(255,255,255,0.3)",
                                },
                              },
                            ]}
                          />
                          <div></div>
                        </>
                      )}
                    </ParentSize>
                  )}
                </FlexboxGrid.Item>
              </Permission>
            )}
            <FlexboxGrid.Item componentClass={Col} xs={24} md={8}>
              <HelpCenter />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item componentClass={Col} xs={24} md={12}>
              <div style={{ marginBottom: "1.8vw" }}>
                <ExternalPosts
                  posts={cmsDashboard?.externalPosts}
                  onChange={handleOnPostsChange}
                />
              </div>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item componentClass={Col} xs={24} md={12}>
              <div style={{ marginBottom: "1.8vw" }}>
                <TopPosts posts={cmsDashboard?.mostLikes} />
              </div>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </>
      )}
    </Page>
  );
};

export default Home;
