//import {lazy} from "react";

import Layout from "@/Components/Layout/Layout";
import { OidcConfig } from "@/Config/OidcConfig";
import { siteTenantURL } from "./Config/Url";

/* Find icon names here: https://rsuitejs.com/tools/icons */
import { Icon } from "rsuite";
import { lazyWithRetry } from "./Helpers/lazyWithRetry";
import { SUBSCRIPTION_FEATURE_CODE } from "./Constants/SubscriptionConstants";

const appId = OidcConfig.client_id;

const routes = [
  {
    path: "/",
    name: "Dashboard",
    Component: lazyWithRetry(() => import("@/Pages/Home")),
    icon: <Icon icon="dashboard" />,
  },
  {
    path: siteTenantURL,
    name: "Public site",
    className: "sc-sidebar__item sc-sidebar__item--external",
    Component: lazyWithRetry(() => import("@/Pages/Home")),
    icon: <Icon icon="web" />,
  },

  {
    path: "/posts",
    name: "Posts",
    claims: [`${appId}.posts.read`],
    Component: lazyWithRetry(() => import("@/Pages/Posts")),
    action: {
      claims: [`${appId}.posts.edit`],
      url: "/posts/editor",
      tooltip: "Add new post",
      icon: <Icon icon="plus" />,
    },
    expanded: true,
    icon: <Icon icon="edit2" />,
    children: [
      {
        path: "/posts/drafts",
        name: "Draft",
        Component: lazyWithRetry(() => import("@/Pages/Posts")),
        claims: [`${appId}.posts.read`],
      },
      {
        path: "/posts/scheduled",
        name: "Scheduled",
        Component: lazyWithRetry(() => import("@/Pages/Posts")),
        claims: [`${appId}.posts.read`],
      },
      {
        path: "/posts/published",
        name: "Published",
        Component: lazyWithRetry(() => import("@/Pages/Posts")),
        claims: [`${appId}.posts.read`],
      },
      {
        path: "/posts/externals",
        name: "From external source",
        Component: lazyWithRetry(() => import("@/Pages/Posts")),
        claims: [`${appId}.posts.read`],
      },
    ],
  },
  {
    path: "/posts/editor",
    name: "New",
    Component: lazyWithRetry(() => import("@/Pages/Posts/PostEditor")),
    hidden: true,
    layout: (props) => <Layout hideSidebar {...props} />,
    claims: [`${appId}.posts.edit`],
  },
  {
    path: "/posts/editor/:id",
    name: "Edit",
    Component: lazyWithRetry(() => import("@/Pages/Posts/PostEditor")),
    hidden: true,
    layout: (props) => <Layout hideSidebar {...props} />,
    claims: [`${appId}.posts.edit`],
  },
  {
    path: "/pages",
    name: "Pages",
    Component: lazyWithRetry(() => import("@/Pages/Pages")),
    icon: <Icon icon="file-o" />,
    claims: [`${appId}.pages.read`],
  },
  {
    path: "/pages/editor",
    name: "New",
    Component: lazyWithRetry(() => import("@/Pages/Pages/PageEditor")),
    hidden: true,
    layout: (props) => <Layout hideSidebar {...props} />,
    claims: [`${appId}.pages.edit`],
  },
  {
    path: "/pages/editor/:id",
    name: "Edit",
    Component: lazyWithRetry(() => import("@/Pages/Pages/PageEditor")),
    hidden: true,
    layout: (props) => <Layout hideSidebar {...props} />,
    claims: [`${appId}.pages.edit`],
  },
  {
    path: "/tags",
    name: "Tags",
    Component: lazyWithRetry(() => import("@/Pages/Tags/Tags")),
    icon: <Icon icon="tag" />,
    claims: [`${appId}.tags.read`],
  },
  {
    path: "/tags/editor",
    name: "Add tag",
    Component: lazyWithRetry(() => import("@/Pages/Tags/TagEditor")),
    hidden: true,
    claims: [`${appId}.tags.edit`],
  },
  {
    path: "/tags/editor/:id",
    name: "Edit tag",
    Component: lazyWithRetry(() => import("@/Pages/Tags/TagEditor")),
    hidden: true,
    claims: [`${appId}.tags.edit`],
  },
  {
    path: "/notifications",
    name: "Notifications",
    Component: lazyWithRetry(() => import("@/Pages/Notifications")),
    icon: <Icon icon="envelope-o" />,
    claims: [`${appId}.notifications.read`],
  },
  {
    path: "/notifications/editor",
    name: "Add notification",
    Component: lazyWithRetry(() =>
      import("@/Pages/Notifications/NotificationEditor/NotificationEditor")
    ),
    hidden: true,
    claims: [`${appId}.notifications.edit`],
  },
  {
    path: "/notifications/editor/:id",
    name: "Edit notification",
    Component: lazyWithRetry(() =>
      import("@/Pages/Notifications/NotificationEditor/NotificationEditor")
    ),
    hidden: true,
    claims: [`${appId}.notifications.edit`],
  },
  {
    path: "/customers",
    name: "Customers",
    Component: lazyWithRetry(() => import("@/Pages/Customers")),
    icon: <Icon icon="people-group" />,
    claims: [`${appId}.customers.read`],
  },
  {
    path: "/customers/editor",
    name: "Add customer",
    Component: lazyWithRetry(() => import("@/Pages/Customers/CustomerEditor")),
    hidden: true,
    claims: [`${appId}.customers.edit`],
  },
  {
    path: "/customers/editor/:id",
    name: "Edit customer",
    Component: lazyWithRetry(() => import("@/Pages/Customers/CustomerEditor")),
    hidden: true,
    claims: [`${appId}.customers.edit`],
  },
  {
    path: "/organization",
    name: "Organization",
    Component: lazyWithRetry(() => import("@/Pages/OrganizationStructure")),
    icon: <Icon icon="group" />,
    claims: [`${appId}.organization.read`],
  },
  {
    path: "/organization/add",
    name: "New organization",
    Component: lazyWithRetry(() =>
      import("@/Pages/OrganizationStructure/OrganizationStructureAdd")
    ),
    hidden: true,
    claims: [`${appId}.organization.edit`],
  },
  {
    path: "/organization/editor/:id",
    name: "Edit organization",
    Component: lazyWithRetry(() =>
      import("@/Pages/OrganizationStructure/OrganizationStructureEditor")
    ),
    hidden: true,
    claims: [`${appId}.organization.edit`],
  },
  {
    path: "/users",
    name: "Users",
    Component: lazyWithRetry(() => import("@/Pages/Users")),
    icon: <Icon icon="peoples-map" />,
    claims: [`${appId}.users.read`],
    action: {
      url: "#",
      tooltip: "Active users",
      icon: <Icon icon="peoples-map" />,
    },
  },

  {
    path: "/users/editor",
    name: "Add user",
    Component: lazyWithRetry(() => import("@/Pages/Users/UserEditor")),
    hidden: true,
    claims: [`${appId}.users.edit`],
  },
  {
    path: "/users/editor/:id",
    name: "Edit user",
    Component: lazyWithRetry(() => import("@/Pages/Users/UserEditor")),
    hidden: true,
    claims: [`${appId}.users.edit`],
  },
  {
    path: "/ticketing",
    name: "Ticketing",
    featureCode: SUBSCRIPTION_FEATURE_CODE.TICKETING,
    Component: lazyWithRetry(() => import("@/Pages/Ticketing")),
    expanded: false,
    icon: <Icon icon="ticket" />,
    claims: [`${appId}.ticketing.read`],
    children: [
      {
        path: "/ticketing/tickets",
        name: "View tickets",
        Component: lazyWithRetry(() => import("@/Pages/Ticketing")),
        claims: [`${appId}.ticketing.read`],
        featureCode: SUBSCRIPTION_FEATURE_CODE.TICKETING,
      },

      {
        path: "/ticketing/categories",
        name: "Ticket categories",
        Component: lazyWithRetry(() =>
          import("@/Pages/Ticketing/TicketingCategories")
        ),
        claims: [`${appId}.ticketing-categories.read`],
        featureCode: SUBSCRIPTION_FEATURE_CODE.TICKETING,
      },
      {
        path: "/ticketing/:id",
        name: "View ticket",
        Component: lazyWithRetry(() =>
          import("@/Pages/Ticketing/TicketReview")
        ),
        hidden: true,
        claims: [`${appId}.ticketing.read`],
        featureCode: SUBSCRIPTION_FEATURE_CODE.TICKETING,
      },
    ],
  },
  {
    path: "/forms",
    name: "Forms",
    featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
    Component: lazyWithRetry(() => import("@/Pages/Forms/Requests/Page")),
    expanded: false,
    icon: <Icon icon="order-form" />,
    claims: [`${appId}.forms.read`, `${appId}.forms.edit`],
    children: [
      {
        path: "/forms/all-requests",
        name: "All requests",
        Component: lazyWithRetry(() => import("@/Pages/Forms/Requests/Page")),
        claims: [`${appId}.forms.read`, `${appId}.forms.edit`],
        featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
      },
      {
        path: "/forms/all-requests/:id",
        name: "All requests",
        Component: lazyWithRetry(() =>
          import("@/Pages/Forms/Requests/RequestReview")
        ),
        claims: [`${appId}.forms.read`, `${appId}.forms.edit`],
        featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
        hidden: true,
      },
      {
        path: "/forms/templates",
        name: "Templates",
        Component: lazyWithRetry(() => import("@/Pages/Forms/Templates/Page")),
        claims: [`${appId}.forms.read`, `${appId}.forms.edit`],
        featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
      },
      {
        path: "/forms/templates/create",
        name: "Templates",
        Component: lazyWithRetry(() =>
          import("@/Pages/Forms/Templates/CreatePage")
        ),
        claims: [`${appId}.forms.read`, `${appId}.forms.edit`],
        hidden: true,
        featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
      },
      {
        path: "/forms/templates/edit/:id",
        name: "Templates",
        Component: lazyWithRetry(() =>
          import("@/Pages/Forms/Templates/CreatePage")
        ),
        claims: [`${appId}.forms.read`, `${appId}.forms.edit`],
        hidden: true,
        featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
      },
      {
        path: "/forms/categories",
        name: "Categories",
        Component: lazyWithRetry(() => import("@/Pages/Forms/Categories/Page")),
        claims: [`${appId}.forms.edit`],
        featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
      },
      {
        path: "/forms/categories/create",
        name: "Categories",
        Component: lazyWithRetry(() =>
          import("@/Pages/Forms/Categories/CategoriesEditor")
        ),
        claims: [`${appId}.forms.read`, `${appId}.forms.edit`],
        featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
        hidden: true,
      },
      {
        path: "/forms/categories/edit/:id",
        name: "Categories",
        Component: lazyWithRetry(() =>
          import("@/Pages/Forms/Categories/CategoriesEditor")
        ),
        claims: [`${appId}.forms.read`, `${appId}.forms.edit`],
        featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
        hidden: true,
      },
    ],
  },
  {
    path: "/surveys",
    name: "Surveys",
    featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
    Component: lazyWithRetry(() =>
      import("@/Pages/Surveys/Completed/CompletedPage")
    ),
    expanded: false,
    icon: <Icon icon="logo-survey" />,
    claims: [`${appId}.surveys.read`, `${appId}.surveys.edit`],
    children: [
      {
        path: "/surveys/completed",
        name: "Completed",
        Component: lazyWithRetry(() =>
          import("@/Pages/Surveys/Completed/CompletedPage")
        ),
        claims: [`${appId}.surveys.read`, `${appId}.surveys.edit`],
        featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
      },
      {
        path: "/surveys/templates",
        name: "Templates",
        Component: lazyWithRetry(() =>
          import("@/Pages/Surveys/Templates/TemplatesPage")
        ),
        claims: [`${appId}.surveys.read`, `${appId}.surveys.edit`],
        featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
      },
      {
        path: "/surveys/templates/create",
        name: "Templates",
        Component: lazyWithRetry(() =>
          import("@/Pages/Surveys/Templates/CreatePage")
        ),
        claims: [`${appId}.surveys.read`, `${appId}.surveys.edit`],
        featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
        hidden: true,
      },
      {
        path: "/surveys/templates/edit/:id",
        name: "Templates",
        Component: lazyWithRetry(() =>
          import("@/Pages/Surveys/Templates/CreatePage")
        ),
        claims: [`${appId}.surveys.read`, `${appId}.surveys.edit`],
        featureCode: SUBSCRIPTION_FEATURE_CODE.FORMS,
        hidden: true,
      },
    ],
  },
  {
    path: "/reporting",
    name: "Reporting",
    Component: lazyWithRetry(() => import("@/Pages/Home")),
    expanded: false,
    icon: <Icon icon="bar-chart" />,
    claims: [`${appId}.reporting.read`],
    children: [
      // {
      //   path: `${siteUrl}/grafana/d/rYdddlPWk/node-exporter-full?orgId=1`,
      //   name: "System",
      //   claims: [`${appId}.reporting.system`],
      // },

      {
        path: "/reporting/ticketing",
        name: "Ticketing",
        featureCode: SUBSCRIPTION_FEATURE_CODE.TICKETING,
        Component: lazyWithRetry(() =>
          import("@/Pages/Reporting/TicketingReport")
        ),
        claims: [`${appId}.reporting.ticketing`],
      },
      {
        path: "/reporting/payments",
        name: "Payments",
        featureCode: SUBSCRIPTION_FEATURE_CODE.INVOICES,
        Component: lazyWithRetry(() => import("@/Pages/Payments")),
        claims: [`${appId}.reporting.payments`],
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    Component: lazyWithRetry(() => import("@/Pages/Settings")),
    hidden: true,
    claims: [`${appId}.settings.read`],
  },
  {
    path: "/settings/general",
    name: "General",
    Component: lazyWithRetry(() => import("@/Pages/Settings/GeneralSettings")),
    hidden: true,
    claims: [`${appId}.settings.edit`],
  },
  {
    path: "/settings/navigation",
    name: "Navigation",
    Component: lazyWithRetry(() =>
      import("@/Pages/Settings/NavigationSettings")
    ),
    hidden: true,
    claims: [`${appId}.settings.edit`],
  },
  {
    path: "/authorizations",
    name: "Authorizations",
    Component: lazyWithRetry(() => import("@/Pages/Authorizations/Roles")),
    expanded: false,
    icon: <Icon icon="table" />,
    claims: [`${appId}.authorizations.read`],
    children: [
      {
        path: "/authorizations/roles",
        name: "Roles",
        Component: lazyWithRetry(() => import("@/Pages/Authorizations/Roles")),
        claims: [`${appId}.authorizations.read`],
      },
      {
        path: "/authorizations/roles/editor",
        name: "New role",
        Component: lazyWithRetry(() =>
          import("@/Pages/Authorizations/RoleEditor")
        ),
        hidden: true,
        icon: <Icon icon="dashboard" />,
        claims: [`${appId}.authorizations.edit`],
      },
      {
        path: "/authorizations/roles/editor/:id",
        name: "Edit role",
        Component: lazyWithRetry(() =>
          import("@/Pages/Authorizations/RoleEditor")
        ),
        hidden: true,
        icon: <Icon icon="dashboard" />,
        claims: [`${appId}.authorizations.edit`],
      },
      {
        path: "/authorizations/assignment",
        name: "Roles assignment",
        Component: lazyWithRetry(() =>
          import("@/Pages/Authorizations/RolesAssignment")
        ),
        claims: [`${appId}.authorizations.assignment`],
      },
    ],
    className: "sc-sidebar__item sc-sidebar__item--last",
  },
  {
    path: "/profile",
    name: "Profile",
    Component: lazyWithRetry(() => import("@/Pages/Profile")),
    hidden: true,
  },
  {
    path: "/profile/notifications",
    name: "My notifications",
    Component: lazyWithRetry(() =>
      import("@/Pages/Profile/MyNotifications/MyNotifications")
    ),
    hidden: true,
  },
  {
    path: "/profile/notifications/:id",
    name: "My notifications",
    Component: lazyWithRetry(() =>
      import("@/Pages/Profile/MyNotifications/MyNotification/MyNotification")
    ),
    hidden: true,
  },
  // {
  //   path: "/integrations",
  //   name: "Integracijski sloj",
  //   Component: lazyWithRetry(() => import("@/Pages/Integrations")),
  //   icon: <Icon icon="info" />,
  // },
  {
    path: "/help",
    name: "Help center",
    Component: lazyWithRetry(() => import("@/Pages/Help")),
    icon: <Icon icon="info" />,
  },
  {
    path: "/help/print",
    name: "Print documentation",
    Component: lazyWithRetry(() => import("@/Pages/Help/HelpPrint")),
    hidden: true,
  },
  {
    path: "/help/users/users",
    name: "Users",
    Component: lazyWithRetry(() => import("@/Pages/Help/Users/HelpUsers")),
    hidden: true,
  },
  {
    path: "/help/customers/customers",
    name: "Customers",
    Component: lazyWithRetry(() =>
      import("@/Pages/Help/Customers/HelpCustomers")
    ),
    hidden: true,
  },
  {
    path: "/help/notifications/notifications",
    name: "Notifications",
    Component: lazyWithRetry(() =>
      import("@/Pages/Help/Notifications/HelpNotifications")
    ),
    hidden: true,
  },
  {
    path: "/help/ticketing/tickets",
    name: "Tickets",
    Component: lazyWithRetry(() => import("@/Pages/Help/Ticketing/Tickets")),
    hidden: true,
  },
  {
    path: "/help/ticketing/working-on-a-ticket",
    name: "Working on a ticket",
    Component: lazyWithRetry(() =>
      import("@/Pages/Help/Ticketing/WorkingOnATicket")
    ),
    hidden: true,
  },
  {
    path: "/help/ticketing/categories",
    name: "Ticket categories",
    Component: lazyWithRetry(() =>
      import("@/Pages/Help/Ticketing/TicketCategories")
    ),
    hidden: true,
  },
  {
    path: "/help/ticketing/reporting",
    name: "Reporting",
    Component: lazyWithRetry(() =>
      import("@/Pages/Help/Ticketing/TicketCategories")
    ),
    hidden: true,
  },
  {
    path: "/help/general/using-the-app",
    name: "Using the application",
    Component: lazyWithRetry(() => import("@/Pages/Help/General/UsingTheApp")),
    hidden: true,
  },
  {
    path: "/help/faq",
    name: "Frequently asked questions",
    Component: lazyWithRetry(() => import("@/Pages/Help/FAQ")),
    hidden: true,
  },
  {
    path: "/help/cms/posts",
    name: "Posts",
    Component: lazyWithRetry(() => import("@/Pages/Help/CMS/Posts")),
    hidden: true,
  },
  {
    path: "/help/cms/posts/add-or-edit-post",
    name: "Adding new or editing an existing post",
    Component: lazyWithRetry(() => import("@/Pages/Help/CMS/AddOrEditPost")),
    hidden: true,
  },
  {
    path: "/help/cms/posts/delete-post",
    name: "Deleting a post",
    Component: lazyWithRetry(() => import("@/Pages/Help/CMS/DeletePost")),
    hidden: true,
  },
  {
    path: "/help/cms/pages",
    name: "Pages",
    Component: lazyWithRetry(() => import("@/Pages/Help/CMS/Pages")),
    hidden: true,
  },
  {
    path: "/help/cms/pages/add-or-edit-page",
    name: "Adding new or editing an existing page",
    Component: lazyWithRetry(() => import("@/Pages/Help/CMS/AddOrEditPage")),
    hidden: true,
  },
  {
    path: "/help/cms/pages/delete-page",
    name: "Deleting a page",
    Component: lazyWithRetry(() => import("@/Pages/Help/CMS/DeletePage")),
    hidden: true,
  },
  {
    path: "/help/organizations/organization",
    name: "Organization structure",
    Component: lazyWithRetry(() =>
      import("@/Pages/Help/Organization/HelpOrganization")
    ),
    hidden: true,
  },
  {
    path: "/help/authorizations/roles",
    name: "Roles",
    Component: lazyWithRetry(() => import("@/Pages/Help/Authorizations/Roles")),
    hidden: true,
  },
  {
    path: "/help/reporting/ticketing",
    name: "Reporting Ticketing",
    Component: lazyWithRetry(() =>
      import("@/Pages/Help/Reporting/HelpTicketing")
    ),
    hidden: true,
    featureCode: SUBSCRIPTION_FEATURE_CODE.TICKETING,
  },
  {
    path: "/help/reporting/payments",
    name: "Reporting Payments",
    Component: lazyWithRetry(() =>
      import("@/Pages/Help/Reporting/HelpPayments")
    ),
    hidden: true,
    featureCode: SUBSCRIPTION_FEATURE_CODE.INVOICES,
  },

  {
    path: "/translations",
    name: "Translations",
    Component: lazyWithRetry(() => import("@/Pages/Translations")),
    claims: [`${appId}.translations.read`],
    icon: <Icon icon="flag" />,
  },
];

export default routes;
